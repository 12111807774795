const viewUserColumns = [
  {
    title: "Title",
    field: "title",
  },
  {
    title: "First Name",
    field: "first_name",
  },
  {
    title: "Last Name",
    field: "last_name",
  },
  {
    title: "Middle Name",
    field: "middle_name",
  },
  {
    title: "Email",
    field: "email",
  },
  {
    title: "Phone",
    field: "mobile",
  },
  {
    title: "User Role",
    field: "role_name",
  },
  {
    title: "Is Provider ?",
    field: "provider",
    formatter: (value) => {
      if (value === true) {
        return "Yes";
      } else {
        return "No";
      }
    },
  },
  {
    title: "Username",
    field: "username",
  },
  {
    title: "User Type",
    field: "user_type",
    formatter: (value) => {
      if (value === "fulltime") {
        return "Full Time";
      } else {
        return "Part Time";
      }
    },
  },
  {
    title: "Provider Number",
    field: "provider_number",
  },
  {
    title: "Prescriber Number",
    field: "prescriber_number",
  },
  {
    title: "Registration Id",
    field: "registration_id",
  },
  {
    title: "Qualifiction",
    field: "qualification",
  },
  {
    title: "HI Number",
    field: "health_identifier",
  },
  {
    title: "HI status",
    field: "hi_status",
  },
  {
    title: "Payee Provider Name",
    field: "payee_provider_name",
  },
  {
    title: "Payee Provider Number",
    field: "payee_provider_number",
  },
  {
    title: "Immunisation Provider Number",
    field: "immunisation_provider_number",
    formatter: (value) => {
      if (!value) {
        return "N/A";
      } else {
        return value;
      }
    },
  },
];
const userTableColumns = [
  {
    dataField: "id",
    text: "id",
    hidden: true,
  },
  {
    dataField: "name",
    text: "Name",
    formatter: (value, row) => {
      return row.title + " " + value;
    },
  },
  {
    dataField: "username",
    text: "Username",
  },
  {
    dataField: "role_name",
    text: "User Role",
  },
  {
    dataField: "email",
    text: "Email",
  },
  {
    dataField: "mobile",
    text: "Mobile",
  },
  {
    dataField: "site",
    text: "Business Name",
    formatter: (value) => {
      return value ? value?.business_name : "N/A";
    },
  },
  {
    dataField: "site",
    text: "Site Name",
    formatter: (value) => {
      return value ? value?.site_name  : "N/A";
    },
  },
  {
    dataField: "status",
    text: "Status",
  },
  {
    dataField: "action",
    text: "Action",
  },
];

const mimsUserTableColumns = [
  {
    dataField: "site",
    text: "Site",
    formatter: (value) => {
      return value ? value?.business_name : "N/A";
    },
  },
  {
    dataField: "id",
    text: "id",
    hidden: true,
  },
  {
    dataField: "name",
    text: "Name",
    formatter: (value, row) => {
      return row.title + " " + value;
    },
  },
  {
    dataField: "username",
    text: "Username",
  },
  // {
  //   dataField: "role_name",
  //   text: "User Role",
  // },
  {
    dataField: "email",
    text: "Email",
  },
  {
    dataField: "mobile",
    text: "Mobile",
  },
  {
    dataField: "mims_data",
    text: "MIMS User ID",
    formatter: (value) => {
      return value ? value?.userID : "N/A";
    },
  },
  {
    dataField: "mims_data",
    text: "Status",
    formatter: (value) => {
      return value.status;
    },
  },
  {
    dataField: "mims_user_id",
    text: "Inactivated",
    formatter: (value, r) => {
      if (value) {
        return "No";
      } else {
        return "Yes";
      }
    },
  },
  {
    dataField: "action",
    text: "Action",
  },
];

const fNames = { viewUserColumns, userTableColumns, mimsUserTableColumns };

export default fNames;
