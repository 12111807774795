import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AuthStateProvider } from "./components/context/auth/authState";
import HeaderState from "./components/context/HeaderState";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { SnackbarProvider, useSnackbar } from "notistack";

ReactDOM.render(
  <Provider store={store}>
    <AuthStateProvider>
      <HeaderState>
        <BrowserRouter>
          <SnackbarProvider
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            autoHideDuration={3000}
          >
            <App />
          </SnackbarProvider>
        </BrowserRouter>
      </HeaderState>
    </AuthStateProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
