import timestampHandler from "../utils/timstampHandler";

const rolesTableColumns = [
  {
    text: "Role Name",
    dataField: "name",
  },
  {
    text: "Paid Role",
    dataField: "paid_role",
    formatter: (value) => {
      return value ? "Yes" : "No";
    },
  },
  {
    text: "List for Site",
    dataField: "list_for_site",
    formatter: (value) => {
      return value ? "Yes" : "No";
    },
  },
  {
    text: "Created On",
    dataField: "created_at",
    formatter: (row) => {
      return timestampHandler(row, "DD/MM/YYYY");
    },
  },
  {
    text: "Last updated on",
    dataField: "updated_at",
    formatter: (row) => {
      return timestampHandler(row, "DD/MM/YYYY");
    },
  },
  {
    text: "Actions",
    dataField: "action",
  },
];

export default {
  rolesTableColumns,
};
